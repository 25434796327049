import React, { Component } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const TypeformFunction = function () {
  var qs,
    js,
    q,
    s,
    d = document,
    gi = d.getElementById,
    ce = d.createElement,
    gt = d.getElementsByTagName,
    id = "typef_orm",
    b = "https://embed.typeform.com/";
  if (!gi.call(d, id)) {
    js = ce.call(d, "script");
    js.id = id;
    js.src = b + "embed.js";
    q = gt.call(d, "script")[0];
    q.parentNode.insertBefore(js, q);
  }
};

class RequestADemoPage extends Component {
  componentDidMount() {
    TypeformFunction();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet title="Request a Demo - Gatsby Storefront">
          <meta
            name="description"
            content="Request a Demo of Gatsby Storefront for Shopify"
          />
        </Helmet>

        <div className="bg-white relative h-screen max-w-xl mx-auto py-16 px-4 overflow-hidden sm:px-6 lg:px-8 ">
          <div
            className="typeform-widget"
            data-url="https://form.typeform.com/to/ygE604?typeform-medium=embed-snippet"
            data-transparency="0"
            style={{
              width: "100%",
              height: "100%",
            }}
          ></div>
        </div>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default RequestADemoPage;
